.ant-layout {
  background: transparent;
}

.ant-layout-sider {
  background-color: $menu-dark-bg;
}

.clear-filter-btn {
  width: 126px;
  height: 36px !important;
  padding: 4px 24px;
  border-radius: 4px;
  background-color: #ebecf0 !important;
  color: #c1c7d0 !important;
  margin-left: 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
.action-btn {
  color: #0073e6 !important;
  font-weight: 500;
  &[disabled] {
    color: rgba(0, 0, 0, 0.25) !important;
  }
}
.editUserName {
  float: right;
  &:hover {
    cursor: pointer;
  }
  &[disabled] {
    cursor: not-allowed;
    filter: invert(100%) sepia(10%) saturate(0%) hue-rotate(130deg) brightness(100%) contrast(100%);
  }
}
.error-msg {
  text-align: left;
  color: red;
  margin-top: -20px;
  margin-left: 5px;
}

.card-wrapper {
  margin-bottom: 0 !important;
  border: 1px solid #e0e3e9;
  border-radius: 0;
  .main-title {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    padding-bottom: 12px;
    margin: 0;
    color: #172b4d;
  }
  .title-description {
    font-weight: 400;
    font-size: 14px;
    line-height: 16.41px;
    color: #4f5561;
  }
  .ant-card-body {
    padding-bottom: 0;
  }
  .inner-card {
    min-height: calc(100vh - 260px);
    .ant-card-body {
      background-color: #ffffff;
    }

    .table-striped-rows {
      clear: both;
      max-width: 100%;
      border: 1px solid #f5f5f5;
    }
    .ant-table {
      line-height: 20px;
      padding: 0px;
      .ant-table-thead tr th {
        color: #5e6c84;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0.5px;
        padding: 14px 16px;
      }
      .ant-table-tbody tr td {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.2px;
        // color: #172B4D;
        padding: 14px 16px;
      }
    }
    .ant-card-bordered {
      border: 0;
    }
  }
  .ant-card {
    margin-bottom: 0;
  }
  .ant-card-head,
  .ant-card-body {
    background-color: #f3f6fa;
    border: none;
  }
  .ant-table-wrapper {
    border: 1px solid #f0f0f0;
    // border-radius: 10px;
  }
  .ant-input-affix-wrapper {
    padding: 5px;
    background-color: #f5f5f5;
  }
  .search-btn,
  .ant-input {
    background-color: #f5f5f5;
    box-sizing: border-box;
    border: 1px solid #dfe1e6;
    border-radius: 4px;
  }
  .ant-tabs-nav {
    margin-bottom: 0;
    text-align: center;
  }
  .ant-tabs-tab {
    padding: 0 !important;
    font-weight: 500;
    font-size: 16px;
    width: 144px;
    line-height: 56px !important;
    background-color: #ffffff;
    border-radius: 8px 0px 0px 0px;
    border: 1px solid #ebecf0;
    margin: 0 !important;
    color: #5e6c84 !important;
  }
  .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #172b4d !important;
  }
  .new-setup-btn {
    background-color: #0073e6;
    color: $white;
    float: right;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    padding-left: 9px !important;
    padding-right: 9px !important;
    // padding: 12px 32px;
    // width: 179px;
    height: 48px;
    border-radius: 4px;
    :disabled,
    &[disabled] {
      font-size: 15px;
      background-color: #e8ebf1 !important;
      color: #8d95a3 !important;
    }
  }

  .version-select {
    width: 200px;
    border-radius: 4px;
    background: #fafbfc;
    border: 1px solid #dfe1e6;
    box-sizing: border-box;
    .ant-select-selector {
      border: 0 !important;
    }
    .ant-select-selection-search-input {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.2px;
      color: #5e6c84;
    }
    .ant-select-arrow {
      color: #253858;
    }
    .ant-select-selection-item {
      font-weight: 500;
    }
  }
}
.btn-block {
  background: #0073e6;
  border-radius: 4px;
  padding: 8px;
  button,
  button:hover {
    background: #0073e6 !important;
    border: none;
    border-radius: 4;
    margin: 0;
    color: #ffffff !important;
  }
  button:first-child {
    border-right: 2px solid #a8a7a7;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    padding-right: 10px;
  }
  button:nth-child(2) {
    padding-left: 10px;
  }
  .ant-btn {
    padding: 0;
    height: 1.5rem;
  }
  .ant-dropdown-menu {
    margin-top: 4px;
  }
  .ant-dropdown-menu-item:hover {
    margin-top: 0;
  }
}
.menu-item:hover {
  background-color: #deebff !important;
}
.width-80 {
  width: 80%;
}
.i-icon {
  margin-top: -4px;
  margin-left: 9px;
  z-index: 1009;
  position: relative;
}
.disabled-license-tooltip,
.disabled-action-tooltip {
  .anticon:hover {
    cursor: pointer !important;
  }
  .ant-tooltip-inner {
    border-radius: 4px;
    font-size: 14px;
    padding: 8px;
    font-weight: normal;
    font-style: normal;
    color: #fff;
  }
}
.disabled-license-tooltip {
  max-width: max-content !important;
  min-width: max-content !important;
}
.menu-disabled-tooltip .ant-tooltip-inner {
  max-width: 300px !important;
  min-width: 0 !important;
}
.disabled-license-tooltip-content {
  font-size: 14px;
}

.disabled-action-tooltip {
  .ant-tooltip-content {
    background-color: #404040;
  }
}

.action-pop-confirm {
  letter-spacing: 0.2px;
  line-height: 24px;
  .popup-title {
    font-size: 18px;
    color: #172b4d;
    margin: 8px 0px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 0px;
  }
  .popup-description,
  .ant-checkbox-wrapper {
    font-size: 16px;
    margin: 8px 0px;
    color: #5e6c84;
    font-weight: 400;
    line-height: 24px;
  }
  .ant-popover-message {
    padding-bottom: 8px;
  }
  .ant-popover-inner-content {
    padding: 0.75rem 1.5rem;
  }
  .ant-checkbox {
    top: 3px;
  }
  .ant-popover-inner-content {
    width: 100%;
  }
}
.action-pop-confirm-vw20 {
  .ant-popover-message-title {
    width: 18vw !important;
    padding: 0;
  }
}
.user-remove-pop-confirm {
  .ant-popover-message-title {
    width: 265px !important;
    text-align: justify;
    padding: 0;
  }
  .ant-popover-inner {
    margin-right: 32px;
  }
  .action-pop-confirm .ant-popover-inner-content {
    padding: 8px !important;
  }
}
.menu-item {
  color: #0052cc !important;
  font-weight: 500 !important;
}
.menu-item-disable {
  color: #c1c7d0 !important;
  font-weight: 500 !important;
}
.light-blue {
  color: #7ebeff;
  &:hover {
    color: #7ebeff;
  }
}
.icon-tooltip,
.icon-tooltip svg,
.icon-tooltip path {
  fill: #0073e6;
  width: 25px;
  height: 25px;
}
.icon-tooltip-disabled,
.icon-tooltip-disabled svg,
.icon-tooltip-disabled path {
  fill: #afb6c2;
  width: 25px;
  height: 25px;
  &:hover {
    cursor: pointer;
  }
}

.myDevice-menu-item {
  min-width: 240px;
}
@media screen and (max-width: 1280px) {
  .ant-table {
    overflow-x: scroll;
  }
}

@media screen and (max-width: 1680) {
  .ant-table {
    overflow-x: scroll;
  }
}

@media screen and (max-width: 1600) {
  .ant-table {
    overflow-x: scroll;
  }
}

@media screen and (max-width: 1366) {
  .ant-table {
    overflow-x: scroll;
  }
}

.disply-deploymnet-status-dtl-ftr {
  border-top: 1px solid #e8e8e8;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 88px;
  background-color: white;
  text-align: center;
}

.panel-content {
  text-align: left !important;
}

.ant-checkbox-inner {
  width: 16px;
  height: 16px;
}

.ant-checkbox-inner::after {
  left: 25%;
  top: 50%;
}

.reviewSpanTitle {
  text-align: left;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #172b4d;
  margin-bottom: 4px;
}

.reviewSpanValue {
  text-align: left;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #5e6c84;
}

.option-group {
  font-size: 30px;
}

.optgroup {
  margin: 0;
}

.ant-select-item.ant-select-item-group {
  font-size: 12px;
}

.reviewForm .ant-card-head-title {
  white-space: normal;
}

.ant-popover-message {
  padding: 0;
}
/*to set color for alternate table rows */
.table-striped-rows tr:nth-child(2n) td {
  background-color: #fafbfc;
}
.table-striped-rows thead {
  background-color: #ffffff;
}
/*to set font size, color, padding to table header */
.ant-table-thead > tr > th {
  background: #ffffff;
  font-size: 12px;
  color: #5e6c84;
  padding-top: 0.527vw;
  padding-bottom: 0.527vw;
}

/*to change the font color and font weight of table data */
.ant-table {
  color: #172b4d;
  font-size: 14px;
  background: #fff;
  font-weight: 400;
}

.custom-table .ant-table {
  line-height: 20px;
  padding: 0px;
  .ant-table-thead tr th {
    color: #5e6c84;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.5px;
    padding: 14px 16px;
  }
  .ant-table-tbody tr td {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    // color: #172B4D;
    padding: 14px 16px;
  }
}
.groups-backgroud {
  background: #f4f5f7;
  border-radius: 5000px;
  padding: 2px 8px;
}
/*to remove the separator line between table header */
.ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not(
    [colspan]
  )::before {
  width: 0px;
}

.grp-frm-title {
  left: 1.25%;
  right: 54.32%;
  top: 19.72%;
  bottom: 77.9%;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-top: 16px;
}
.grp-ipt-frm {
  border: 1px solid #dfe1e6;
  box-sizing: border-box;
  border-radius: 4px;
  width: 273px;
  margin-top: 16px;
  margin-bottom: 16px;
}
.grp-search-frm {
  background: #f5f5f5;
  border: 1px solid #dfe1e6;
  box-sizing: border-box;
  border-radius: 4px;
  max-width: 422px !important;
  max-height: 40px !important;
  left: 0px;
  right: 0.16px;
  top: 0px;
  bottom: 0px;
}

.grp-clear-filter-btn {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #c1c7d0;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 8px;
  border-radius: 4px;
  margin-left: 20px;
}
.grp-clear-filter-btn span {
  position: static;
  width: 78px;
  height: 24px;
  left: 24px;
  top: 8px;
}
.ant-input {
  background: #f5f5f5;
}
.dev-set-status {
  background: #0073e6 !important;
  border-radius: 4px !important;
  float: right;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 70px;
  position: static;
  max-width: 162px;
  max-height: 48px;
  /* max-width: 22px; */
}
.dev-set-status span {
  color: #ffffff;
}
.dsply-dev-status-dtl-ftr {
  border-top: 1px solid #e8e8e8;
  /* position: fixed; */
  left: 0;
  bottom: 0;
  width: 100%;
  height: 88px;
  background-color: white;
  text-align: center;
  /* margin-top: 60px; */
  /* display: flex */
}
.table .ant-table-wrapper {
  border: 1px solid #f0f0f0;
}
.thick-border {
  border: 1px solid rgb(187, 187, 187) !important;
}
.display-flex {
  display: flex;
}
.align-items-center {
  align-items: center;
}
.justify-center {
  justify-content: center;
}
.justify-space-btwn {
  justify-content: space-between;
}
.justify-flex-end {
  justify-content: flex-end;
}
.flex-direction-col {
  flex-direction: column;
}
.space-btw-col {
  align-content: space-between;
}
.grp-tbl-col-tag {
  background-color: rgb(244, 245, 247);
  border-radius: 5000px;
}
@media (max-width: 576px) {
  .grp-clear-filter-btn {
    margin-top: 10px !important;
  }
}
.ant-dropdown {
  z-index: 1050 !important;
}
.thick-icon {
  color: #fff;
  stroke: #fff;
  stroke-width: 38px;
}
.btn-footerr {
  border-radius: 4px;
  background-color: #0073e6;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #ffffff;
  text-align: center;
}
.errormessage {
  font-size: 15px;
  color: red;
  margin: auto;
  width: 50%;
  padding: 10px;
}

@for $i from 0 through 250 {
  .windowPaddingLeft_#{$i} {
    padding-left: #{$i}px !important;
  }
}

.helper-text-medium {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #5e6c84;
  margin-bottom: 4;
}
.helper-text-bold {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #172b4d;
  text-align: center;
  margin-bottom: 4;
}
.outer-line-btn-primary {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #0073e6 !important;
  background: #ffffff;
  border: 1px solid #0073e6;
  border-radius: 4px;
  &:hover,
  &:focus {
    border-color: #0073e6 !important;
  }
}
.outer-line-btn-border {
  border: 1px solid #0073e6;
}

.border-r-4 {
  border-radius: 4px !important;
  .ant-tooltip-content,
  .ant-tooltip-inner,
  .ant-modal-content {
    border-radius: 4px !important;
  }
}
.border-r-0 {
  border-radius: 0px !important;
}
.border-r-5 {
  border-radius: 5px !important;
}
.mt10 {
  margin-top: 10px;
}
.ml10 {
  margin-left: 10px;
}
.mr10 {
  margin-right: 10px;
}
.mlr8 {
  margin-right: 8px;
  margin-left: 8px;
}
.mb16 {
  margin-bottom: 16px;
}
.mb22 {
  margin-bottom: 22px;
}
.ml5 {
  margin-left: 5px !important;
}
.mr5 {
  margin-right: 5px !important;
}
.width-mx-c {
  max-width: max-content;
}
.width-40p {
  width: 40%;
}
.p-8-14 {
  padding: 8px 14px;
}
.width-c {
  width: fit-content;
}
.width-70 {
  width: 70px;
}
.width-100 {
  width: 100px;
}
.col-min-width {
  min-width: 25px;
}
.margin-r--3 {
  margin-right: -3px;
}
.border-r-16 {
  border-radius: 16px !important;
}
.border-default {
  border: 1px solid #e0e3e9 !important;
}
.badge-border-default {
  border: 1px solid #ff8692 !important;
}
.json-ui-err {
  background-color: #ffab00;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  .ant-btn-link:hover {
    background-color: transparent !important;
  }
}
.json-ui-err-dismiss-btn,
.bold-text {
  font-size: 16px !important;
  height: 24px !important;
  margin: 0;
  padding: 0;
  color: #172b4d;
}
.warning-banner-flex {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.json-ui-err-msg {
  font-size: 14px !important;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #172b4d !important;
}
.warning-banner {
  background-color: #ffab00;
  white-space: pre-wrap;
  display: flex;
  align-items: center;
}
.dashboard-banner {
  background-color: #ffffff;
  border-radius: 16px;
  border: 1px solid #e0e3e9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  .ant-btn-link:hover {
    background-color: transparent !important;
  }
}
.custom-colour-lightmode {
  color: #1d1e23 !important;
}
.custom-colour-subtitle {
  color: #545963 !important;
}

.custom-bg-myapps-light {
  background-color: #fffaf2;
  border: 1px solid #ffd396;
  border-radius: 4px;
}
.custom-bg-myapps-no-border {
  background-color: #fffaf2;
}
.text-decoration-underline {
  text-decoration: underline;
}
.custom-bg-white {
  background-color: #ffffff;
}
.custom-bg-gray {
  background-color: #f4f5f7 !important;
  color: #5e6c84;
}
.custom-gray {
  color: #5e6c84;
}
.custom-main-blue {
  color: #004d99 !important;
}
.custom-bg-primary {
  background: #0052cc !important;
}
.max-width-window75 {
  max-width: 75% !important;
}
.top-window25 {
  top: 25% !important;
}
.top-window15 {
  top: 15% !important;
}
.fs-20 {
  font-size: 20px;
  line-height: 28px;
}
.fs-16 {
  font-size: 16px;
  line-height: 24px;
}
.fs-14 {
  font-size: 14px;
  line-height: 20px;
}
.fs-12 {
  font-size: 12px;
  line-height: 16px;
}
.fsn-14 {
  font-size: 14px;
}
.config-input {
  height: 44px;
  width: 448px;
}
.cursor-pointer {
  cursor: pointer;
}
.fs-32 {
  font-size: 32px;
  line-height: 40px;
}
.text-dark-primary {
  color: $text-dark-primary !important;
}
.text-dark-subdued {
  color: #6b778c;
}
.light-model-text {
  color: #1d1e23;
}
.main-neutral-co0l-800-text {
  color: #2c2f36;
}
.main-neutral-co0l-600-text {
  color: #4f5561 !important;
}
.float-left {
  float: left !important;
}
.float-right {
  float: right !important;
}
.padding-top-4 {
  padding-top: 4px;
}
.padding-12-32,
.ant-card-extra .padding-12-32,
.card-wrapper .padding-12-32 {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  padding-left: 32px !important;
  padding-right: 32px !important;
}
.white-space-nowrap {
  white-space: nowrap;
}
.white-space-normal {
  white-space: normal;
}
.light-mode-action-primary-bg {
  background-color: #0073e6 !important;
}
.light-mode-action-primary-text {
  color: #0073e6 !important;
}
.disabledBtn {
  color: rgba(0, 0, 0, 0.25) !important;
}
.light-mode-action-error-bg {
  background-color: #de350b !important;
}
.light-mode-action-error-text {
  color: #de350b !important;
}
.light-mode-negative-error-text {
  color: #d70015 !important;
}
.light-mode-negative-error-bg {
  background-color: #d70015 !important;
}
.neutrals-n0-text {
  color: #ffffff;
}
.version-list-i-icon {
  color: #d70015;
  margin-top: -2px;
  margin-left: 4px;
  width: 18px;
  height: 18px;
}
.version-err-i-icon {
  margin-top: -2px;
  margin-right: 2px;
}
.app-version-select {
  min-width: 100px;
}
.not-supported-version-error-blk {
  max-width: 300px;
  align-items: center;
  margin-top: -20px;
}
.actions-space {
  width: 100%;
  // justify-content: space-between;
  padding-right: 10px;
  gap: 0px !important;
}
.menu-item-icon .ant-dropdown-menu-title-content {
  display: inline-flex;
  align-items: center;
}
.position-right {
  position: absolute;
  right: 10px;
}
.disable-action-icon-btn {
  .ant-btn-primary,
  .ant-btn-primary:active,
  .ant-btn-primary[disabled],
  .ant-btn-primary:hover {
    background-color: #e8ebf1 !important;
    border: 1px solid #e8ebf1 !important;
    color: #929aa9 !important;
    width: max-content;
  }
}
.disable-actions {
  display: inline-flex;
  flex-wrap: wrap;
}
.disable-actions div:first-child {
  padding-right: 12px;
}
.save-tooltip {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.save-tooltip-icon {
  width: 2em;
}
.flex-1 {
  flex: 1;
}
.save-tooltip-content p {
  white-space: nowrap;
}
.questionCircle {
  color: white;
  border-radius: 50%;
  background: #36b37e;
  padding: 5px;
}
.main-neutral-co02-800-text {
  color: #c1c7d0;
}
.color-light-primary {
  color: rgb(255, 255, 255);
}
.color-light-secondary {
  color: rgba(255, 255, 255, 0.6);
}
.i-icon-16 svg {
  width: 16px;
  height: 16px;
}
.postion-top-right {
  position: absolute;
  top: 0px;
  right: 0px;
}
.icon-button {
  margin: 0;
  display: inline-flex;
  padding: 0 !important;
  height: 48px !important;
  align-items: center;
  margin-left: 2vw;
  margin-top: 10px;
  color: #fff;
  width: calc(100% - 2vw);
  &:hover,
  &:focus {
    color: #fff !important;
  }
  .btn-text {
    flex: 1;
    text-align: center;
  }
}
.google-bg {
  background-image: url('../../../../../public/img/logo_google.png');
}
.google-drive-bg {
  background-image: url('../../../../../public/img/logo_google_drive.png');
}
.text-align-justify {
  text-align: justify;
}
.text-align-center {
  text-align: center;
}
.text-align-right {
  text-align: right;
}
.line-height-22 {
  line-height: 22px !important;
}
.line-height-16 {
  line-height: 16px !important;
}
.btn-img {
  float: left;
  width: 16px;
  height: 16px;
  background-size: 20px;
  padding: 21px;
  background-repeat: no-repeat;
  background-position: center;
  background-color: white;
  margin-left: 2px;
}
.full-width {
  width: 100%;
}
.full-height {
  height: 100%;
}
.google-bg-color1 button,
.google-bg-color {
  background-color: #4285f4 !important;
}
.no-wrap-third-col .ant-table-measure-row {
  display: none;
}
.ant-table-cell:has(.editHeader) {
  .name-editor-blk {
    display: flex;
    justify-content: space-between;
    width: auto;
  }
}
.no-wrap-third-col .ant-table-content tr {
  td:nth-child(3) {
    &:has(div) {
      display: inline-flex;
      flex-wrap: nowrap;
    }
    white-space: nowrap;
    flex: 1;
    .editHeader {
      float: right;
    }
  }
  td:nth-child(3) {
    // flex:1;
    align-items: center;
    .ant-form-item {
      margin-bottom: 0 !important;
      input {
        min-width: 10vw;
        font-size: 14px;
      }
    }
    .ant-form-item-explain-error {
      font-size: 12px;
    }
    .editHeader {
      margin-left: auto;
    }
  }
}

.menu-items-align-center-verticle {
  display: inline-flex;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  overflow: visible !important;
  height: 18px !important;
}
.googleDrive-indicator-table tr {
  td:nth-child(2) {
    position: relative;
    .ant-table-row-indent {
      float: none;
    }
    .warning-icon {
      color: #ffab00 !important;
      position: absolute;
      left: 0px;
    }
  }
}
.hovernow {
  padding: 16px 8px 4px;
  border-radius: 6px;
  &:hover {
    background-color: #f3f6fa !important;
    .anticon-file-pdf {
      background-color: #fff !important;
    }
  }
}
.hovernow1 {
  height: 40px;
  width: 40px;
  padding: 8px 8px;
  border-radius: 6px;
  &:hover {
    background-color: #f3f6fa !important;
  }
  &:active {
    background-color: #edeff4 !important;
  }
}
.action-icons-list {
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
}
.custom-table-paddings .ant-table .ant-table-tbody tr th,
.custom-table-paddings .ant-table .ant-table-tbody tr td {
  padding: 6px 8px !important;
}
#Device_Info_Device_Group .ant-select-dropdown {
  overflow: hidden;
}
.android-not-supported {
  text-align: left;
  color: red;
  margin-bottom: -12px;
}
.choiceClass {
  background: #fafbfc !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;
  width: 314px !important;
  height: 44px !important;
}
.license-dropdown-select {
  width: 150px;
  position: absolute;
  right: 0;
  top: -6px;
  z-index: 100;
  border: 1px solid #dfe1e6;
  border-radius: 4px;
  .ant-select-selection-item {
    font-weight: 400 !important;
    font-size: 16px !important;
    color: #5e6c84 !important;
  }
  .ant-select-selector {
    background-color: #fafbfc !important;
  }
}

.edit-btn {
  left: 1vw;
  color: #0052cc;
  font-weight: 500;
  background-color: white;
  &:hover,
  &:focus {
    background-color: white;
    color: #0052cc;
  }
}
.col-selection-btn-active {
  background-color: #f5f5f5 !important;
  border-color: #f5f5f5 !important;
}
.col-selection-dropdown-trigger {
  line-height: 0px !important;
  padding: 15px !important;
  border: none;
  border-radius: 4px;
  :hover {
    background-color: #f5f5f5 !important;
    border-color: #f5f5f5 !important;
    // color: #F5F5F5;
  }
}
.col-selection-dropdown {
  width: 200px;
  z-index: 1029 !important;
  filter: drop-shadow(0px 2px 12px rgba(0, 0, 0, 0.12));
}
.colList-icon {
  path {
    fill: #253858;
  }
  :hover {
    cursor: pointer;
  }
}
.more-outline {
  font-size: 26px;
  color: #0073e6;
}
.more-outline:hover {
  background-color: #f3f6fa;
  cursor: pointer;
}
.footer-bulk-action {
  position: fixed;
  background-color: #fafafb;
  bottom: 0px;
  width: 100%;
  padding-left: 0;
  padding-bottom: 0;
  padding-top: 0;
}
.footer-hr {
  color: #0052cc;
  background-color: #0052cc;
  height: 2px;
  margin-top: 0;
  margin-bottom: 0;
}

.favourite-color {
  color: #ffd700;
}

.upload-btn-active {
  color: '#FFFFFF';
  width: 135px !important;
}
.upload-btn-disabled {
  color: '#C1C7D0';
  width: 135px !important;
}
.height-50 {
  height: 50px !important;
}
.yellow-color {
  color: #ffab00;
}
.footer-beta {
  text-align: center;
  position: absolute;
  bottom: 16px;
  width: 100%;
}
.overflowTable {
  overflow: auto;
  width: 100%;
}

.p-lr-4-4 {
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.card-body-full-height .ant-card-body {
  height: 100% !important;
}

.design_card_wrapper_img {
  position: absolute;
  right: 5px;
  width: 50px;
  height: 50px;
  top: 0px;
}
.ant-menu-inline-collapsed-tooltip .ant-tooltip-content .ant-tooltip-inner svg {
  margin-right: 8px;
}

.nav-dropdown {
  .ant-dropdown-menu {
    border-radius: 0;
  }
  .ant-dropdown-menu-item {
    background-color: inherit !important;
    padding: 0;
    .ant-dropdown-menu-title-content {
      padding: 8px 15px;
      line-height: 20px;
      color: #172b4d !important;
      font-size: 14px;
      letter-spacing: 0.2px;
      .anticon {
        font-size: 1.05rem;
      }
      &:hover {
        background-color: $dropdown-bg-hover !important;
      }
    }
  }
}

.profile-dropdown {
  color: hsla(0, 0%, 100%, 0.8);
  &:hover {
    cursor: pointer;
    color: $white;
  }
}

.yellow-warning-icon {
  color: #ffab00;
  position: absolute;
  margin-top: 4px;
}
