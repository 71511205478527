.footer {
  height: $footer-height;
  display: flex;
  margin: 0 $layout-content-gutter;
  align-items: center;
  border-top: 1px solid $border-color;
  justify-content: space-between;

  @include screen-mobile {
    justify-content: center;
    flex-direction: column;
  }
}
